<template>
  <div style="display: flex; flex-direction: row; gap: 10px">
    <button class="button" style="margin: 0 0 10px 0" @click="download">
      Выгрузка статусов регионов
    </button>

    <button class="button" style="margin: 0 0 10px 0" @click="downloadRegionsData">
      Выгрузка данных регионов
    </button>
    <button class="button" style="margin: 0 0 10px 0" @click="downloadFoivData">
      Выгрузка данных ФОИВов
    </button>
    <button class="button" style="margin: 0 0 10px 0" @click="downloadForMon">
      Выгрузка для МОН
    </button>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';

  export default {
    name: 'DownloadAdmin',
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
        isRegion: 'isRegion',
      }),
    },
    data() {
      return {};
    },
    methods: {
      async download() {
        let responseAxios = await axios.get(`/api/reports/admin/regionStat`, {
          responseType: 'blob',
          token: `${this.getAuthData.token}`,
        });
        this.saveByteArray('Report.xlsx', responseAxios.data);
      },

      async downloadForMon() {
        let responseAxios = await axios.get(`/api/reports/monExcelReport`, {
          responseType: 'blob',
          token: `${this.getAuthData.token}`,
        });
        this.saveByteArray('ReportForMon.xlsx', responseAxios.data);
      },

      async downloadRegionsData() {
        let responseAxios = await axios.get(`/api/reports/admin/region`, {
          responseType: 'blob',
          token: `${this.getAuthData.token}`,
        });
        this.saveByteArray('ReportForRegions.xlsx', responseAxios.data);
      },
      async downloadFoivData() {
        let responseAxios = await axios.get(`/api/reports/admin/foiv`, {
          responseType: 'blob',
          token: `${this.getAuthData.token}`,
        });
        this.saveByteArray('ReportForFOIV.xlsx', responseAxios.data);
      },

      saveByteArray(reportName, byte) {
        let blob = new Blob([byte], { type: 'application/xlsx' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
      },
    },
  };
</script>

<style scoped></style>
